import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import Vue from 'vue';

// Application
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import EditRetirementFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/application/services/edit-retirement-fund-goal-plan-current-plan-service';

// Domain
import { CurrentPlanEntity }
  from '@/modules/flagship/edit-plan-goals/edit-retirement-fund-goal-plan/domain/entities/current-plan-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class EditRetirementFundCurrentPlanViewModel {
  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_goal_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.EDIT_RETIREMENT_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditRetirementFundGoalPlanCurrentPlanService;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-retirement-fund-goal-plan.edit_retirement_fund_current_plan';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  plan_information = [
    {
      label: this.translate('target_amount'),
      value: '',
    },
    {
      label: this.translate('current_amount'),
      value: '',
    },
    {
      label: this.translate('monthly_contribution'),
      value: '',
    },
    {
      label: this.translate('retirement_age'),
      value: '',
    },
    {
      label: this.translate('desired_pension'),
      value: '',
    },
  ]

  associated_product_id = '';

  retirement_fund_id = '';

  initialize = async () => {
    await this.loadRetirementFundInvestorGoalInformation();
  }

  getAmountFormatted(amount: number) {
    return `${currencyFormat(amount)} MXN`;
  }

  loadRetirementFundInvestorGoalInformation = async () => {
    try {
      const {
        retirement_age_adjusted, monthly_pension_adjusted, investor_goal, id,
      } = await this.get_retirement_investor_goal_query.execute();
      this.plan_information[0].value = this
        .getAmountFormatted(investor_goal.accumulated_amount || 0);
      this.plan_information[1].value = this
        .getAmountFormatted(investor_goal.active_goal_amount || 0);
      this.plan_information[2].value = this
        .getAmountFormatted(investor_goal.monthly_required_amount || 0);
      this.plan_information[3].value = `${retirement_age_adjusted} ${this.translate('years')}`;
      this.plan_information[4].value = `${this
        .getAmountFormatted(monthly_pension_adjusted || 0)} ${this.translate('monthly')}`;
      this.associated_product_id = investor_goal.associated_product_id!;
      this.retirement_fund_id = id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_retirement_fund_information'));
    }
  }

  setCurrentPlantInformation = (current_plan_service: CurrentPlanEntity) => {
    this.current_plan_service.setCurrentPlanInformation(current_plan_service);
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }

  nextStep = () => {
    const current_plan: CurrentPlanEntity = {
      target_amount: this.plan_information[0].value,
      current_amount: this.plan_information[1].value,
      monthly_required_amount: this.plan_information[2].value,
      retirement_age: this.plan_information[3].value,
      monthly_pension: this.plan_information[4].value,
      associated_product_id: this.associated_product_id,
      retirement_fund_id: this.retirement_fund_id,
    };
    this.setCurrentPlantInformation(current_plan);
    this.view.$emit('nextStep');
  }
}
